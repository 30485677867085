import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Form } from "react-bootstrap";
import { get, post } from "../../Utility/HttpService";
import { storage } from "../../Utility/CartStorage";
import { Storage as _storage } from "../../Utility/Storage";
import {
  getDiscountsForFoodItems,
  getPassInfoByEncryptedText,
} from "../../Utility/URLService";
import { CartItemKey, PassInfoKey, ItemTreeRoot } from "../../Utility/Config";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../Utility/Spinner";
import BarcodeReader from "../BarcodeReader/BarcodeReader.Component";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";
import { KIOSKSetting } from "../../Utility/Config";
import CardSwipe from "../../Utility/CardReader";
import DynamsoftBarcodeScanner from "../DynamsoftBarcodeReader/DynamsoftBarcodeReader";
import { commonHelper } from "../../Utility/Common";

class PassInfoDialog extends Component {
  //state = {};
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      passNumber: "",
      emailId: "",
      phoneNumber: "",
      guestNumber: "",
      scanner: false,
      kioskSetting: JSON.parse(_storage.getItem(KIOSKSetting)),
      data: "",
      isPassApplied: false,
      isSendEncryptedTextToPassInfo: true,
      validation: {
        success: true,
        message: "",
      },
      passValidation: {
        passNumber: "",
        firstName: "",
        lastName: "",
      },
      spinner: {
        show: false,
      },
      toaster: [],
      isCameraAvailable: false,
      isShowPassInfoSection: false,
      isPassInfoManuallyEntered: false,
    };
    CardSwipe.init({
      success: this.handleSwipeSuccess,
      debug: false,
    });
  }
  handleCheckCameraAvailable = () => {
    let { isCameraAvailable, kioskSetting } = this.state;
    isCameraAvailable = false;
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        var camera = devices.find((device) => device.kind == "videoinput");
        if (camera && camera.deviceId) {
          isCameraAvailable = true;
        }
        this.setState({ isCameraAvailable }, () => {
          if (
            !(kioskSetting && kioskSetting.IsKioskActive) &&
            !isCameraAvailable
          )
            this.setState({ isShowPassInfoSection: true });
        });
      })
      .then((stream) => {})
      .catch((e) => {
        console.error(e);
        this.setState({ isCameraAvailable: false }, () => {
          if (
            !(kioskSetting && kioskSetting.IsKioskActive) &&
            !isCameraAvailable
          )
            this.setState({ isShowPassInfoSection: true });
        });
      });
  };
  componentDidMount() {
    let { kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      this.setState({ isShowPassInfoSection: false });
    }
    this.handleCheckCameraAvailable();
    this.handleSwipeOption();
    this.showSpinner(false);
  }

  handleSwipeOption = () => {
    let { kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      CardSwipe.enable();
    } else {
      CardSwipe.disable();
    }
  };

  handleSwipeSuccess = (swipeData) => {
    // For Test GiftCard
    // swipeData = {
    //   line1: "%B6035242861634420^9866"
    // };
    let message = "Please swipe valid card";
    if (swipeData && swipeData.line1) {
      let data = swipeData.line1.split(";");
      if (data[0]) {
        let passData = data[0].split("^");
        if (passData[0]) {
          let passInfo = passData[0].replace(/[^\d]/g, "");
          if (passInfo) {
            this.getPassInfoByEncryptedText(passInfo, true);
          }
        }
      } else {
        this.showToasterMessage(message, "w");
      }
    }
  };

  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  componentWillMount() {
    this.showSpinner(true);
  }

  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };

  handleApplyClick = (isPassInfoManuallyEntered = false) => {
    const passNumber = this.state.passNumber;
    const lastName = this.state.lastName;
    const firstName = this.state.firstName;
    const emailId = this.state.emailId;
    const phoneNumber = this.state.phoneNumber;
    const passValidation = this.state.passValidation;

    let foodItems = [];
    if (!passNumber) {
      passValidation.passNumber = " Pass Number is Required";
    } else {
      passValidation.passNumber = "";
    }
    if (!firstName) {
      passValidation.firstName = " First Name is Required";
    } else {
      passValidation.firstName = "";
    }
    if (!lastName) {
      passValidation.lastName = " Last Name is Required";
    } else {
      passValidation.lastName = "";
    }
    this.setState({ passValidation, isPassInfoManuallyEntered });

    if (passNumber && lastName && firstName) {
      let storedItems = storage.getAllFoodItems();
      if (storedItems.foodItems.length > 0) {
        let storedfoodItems = storedItems.foodItems;
        storedfoodItems.forEach((foodItem) => {
          let customizations = foodItem.customizations;
          delete foodItem.customizations;
          foodItems.push({ customizations, foodItem });
        });
        const restaurantInfo = storage.getRestaurantInfo();

        const passInfoWithFoodItems = {
          passNumber,
          lastName,
          firstName,
          emailId,
          phoneNumber,
          foodItems,
          salesPoint: restaurantInfo.salesPointName,
          rootName: ItemTreeRoot,
        };
        this.getDiscountsforFoodItems(passInfoWithFoodItems);
      } else {
        return false;
      }
    }
  };

  getDiscountsforFoodItems = (passInfoWithFoodItems) => {
    post(getDiscountsForFoodItems, passInfoWithFoodItems)
      .then((result) => result.data)
      .then((data) => {
        this.handleDiscountResponses(data);
      })
      .catch((error) => {
        this.showToasterMessage(
          "You have not entered a valid pass number",
          "e"
        );
        this.setState({ isSendEncryptedTextToPassInfo: true });
      });
  };

  updateFoodItemsWithSpecialDiscounts = (response) => {
    let foodItems = [];
    response.foodItems.forEach((item) => {
      let foodItem = item.foodItem;
      let specialDiscount = foodItem.specialDiscount;
      if (specialDiscount != null) {
        specialDiscount.actualPrice = foodItem.actualPrice * foodItem.quantity;
        if (
          specialDiscount.discountAmount &&
          specialDiscount.discountAmount > 0
        )
          specialDiscount.discount =
            specialDiscount.actualPrice - specialDiscount.discountAmount;
        else specialDiscount.discount = 0;
      }
      let customizations = item.customizations;
      customizations.forEach((customization) => {
        customization.customizationOptions.forEach((option) => {
          let _specialDiscount = option.specialDiscount;
          if (_specialDiscount != null) {
            _specialDiscount.actualPrice =
              option.actualPrice * foodItem.quantity;
            if (
              _specialDiscount.discountAmount &&
              _specialDiscount.discountAmount > 0
            )
              _specialDiscount.discount =
                _specialDiscount.actualPrice - _specialDiscount.discountAmount;
            else _specialDiscount.discount = 0;
          }
        });
      });
      foodItem.customizations = customizations;
      foodItems.push(foodItem);
    });
    let cartItems = storage.getAllFoodItems();
    cartItems.employeePurchase = response.employeePurchase ? true : false;
    cartItems.foodItems = foodItems;
    cartItems = storage.calculateCartFigures(cartItems);
    _storage.setItem(CartItemKey, JSON.stringify(cartItems));
  };

  SavePassInformationIntoStorage = () => {
    let passInfo = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      passNumber: this.state.passNumber,
      emailId: this.state.emailId,
      phoneNumber: this.state.phoneNumber,
      guestNumber: this.state.guestNumber,
      isPassApplied: this.state.isPassApplied,
      specialText: "",
      isPassInfoManuallyEntered: this.state.isPassInfoManuallyEntered,
    };
    _storage.setItem(PassInfoKey, JSON.stringify(passInfo));
  };

  handleDiscountResponses = (response) => {
    if (!response.validationSuccess) {
      this.showToasterMessage(response.validationMessage, "e");
    } else {
      this.showToasterMessage("Your discount has been applied!", "s");
      let specialText = "";
      if (response.phoneNumber) {
        response.phoneNumber = response.phoneNumber.replace(/[^\d]/g, "");
        var phoneNoRegex = /^\d{10}$/;
        response.phoneNumber = response.phoneNumber.match(phoneNoRegex)
          ? response.phoneNumber
          : "";
      }
      if (response.emailId) {
        var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        response.emailId = emailRegex.test(response.emailId)
          ? response.emailId
          : "";
      }
      this.setState(
        {
          emailId: response.emailId,
          phoneNumber: response.phoneNumber,
          isPassApplied: true,
          guestNumber: response.guestNumber,
        },
        () => {
          this.SavePassInformationIntoStorage();
        }
      );

      if (response.specialNames.length > 0) {
        this.updateFoodItemsWithSpecialDiscounts(response);
        if (response.specialNames.length === 1) {
          specialText = response.firstName
            ? `${commonHelper.capitalizeFirstLetter(response.firstName)}, ${
                window.ENV.DefaultSpecialText
              }`
            : window.ENV.DefaultSpecialText;
          specialText = response.specialNames[0].text
            ? `${specialText} Special ${response.specialNames[0].text} applied`
            : specialText;
        } else {
          let names = "";
          response.specialNames.forEach((special) => {
            if (names) names += ", ";
            names += "'" + special.text + "'";
          });
          specialText = response.firstName
            ? `${commonHelper.capitalizeFirstLetter(response.firstName)}, ${
                window.ENV.DefaultSpecialText
              }`
            : window.ENV.DefaultSpecialText;
          specialText = names
            ? `${specialText} Special ${names} applied`
            : specialText;
        }
      } else {
        specialText = response.nonSpecialMessage
          ? response.nonSpecialMessage
          : response.firstName
          ? `${commonHelper.capitalizeFirstLetter(response.firstName)}, ${
              window.ENV.DefaultSpecialText
            }`
          : window.ENV.DefaultSpecialText;
      }
      this.props.handleSpecialInfo(specialText);
    }
  };

  handleChange = (event) => {
    this.props.checkAndRefreshScreenTime();
    this.setState({ [event.target.id]: event.target.value });
  };

  handleOnEnter = () => {
    this.setState({
      validation: {
        success: true,
        message: "",
      },
    });
  };

  handleClose = () => {
    CardSwipe.disable();
    this.props.onClose();
  };

  getPassInfoByEncryptedText = (encryptedText, isSwipe = false) => {
    let url = `${getPassInfoByEncryptedText}${encryptedText}&isSwipe=${isSwipe}`;
    get(url)
      .then((result) => result.data)
      .then((response) => {
        if (response && response.status) {
          let data = JSON.parse(response.data);
          this.setState(
            {
              passNumber: data.PassNumber,
              firstName: data.FirstName,
              lastName: data.LastName,
              emailId: data.EmailId,
              phoneNo: data.PhoneNumber,
              isSendEncryptedTextToPassInfo: false,
            },
            () => {
              this.handleApplyClick();
            }
          );
        } else {
          this.setState({ isSendEncryptedTextToPassInfo: true });
        }
      })
      .catch((error) => {
        this.setState({ isSendEncryptedTextToPassInfo: true });
        console.log(error);
        this.showToasterMessage("Wrong pass info", "w");
      });
  };

  getYourDetails = () => {
    return (
      <Fragment>
        <div className="your-details">
          <Form.Group controlId="passNumber">
            <Form.Label>Pass Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Pass Number"
              value={this.state.passNumber}
              onChange={this.handleChange}
            />
            <div className="errorMessage">
              <span className="passError">
                {this.state.passValidation.passNumber}
              </span>
              <span className="required">7-12 Digit Number Required</span>
            </div>
          </Form.Group>
          <Form.Group controlId="firstName">
            <Form.Label>Passholder First Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Passholder First Name"
              value={this.state.firstName}
              onChange={this.handleChange}
            />
            <div className="errorMessage">
              <span className="passError">
                {this.state.passValidation.firstName}
              </span>
              <span className="required">Required</span>
            </div>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Passholder Last Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Passholder Last Name"
              value={this.state.lastName}
              onChange={this.handleChange}
            />
            <div className="errorMessage">
              <span className="passError">
                {this.state.passValidation.lastName}
              </span>
              <span className="required">Required</span>
            </div>
          </Form.Group>
        </div>
      </Fragment>
    );
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };
  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  handleClosePassInfo = () => {
    CardSwipe.disable();
    this.props.onClose();
  };
  checkBarcodeReaderScanAgain = () => {
    return this.state.isSendEncryptedTextToPassInfo;
  };
  handlePassInfoSection = () => {
    CardSwipe.disable();
    this.setState({ isShowPassInfoSection: true });
  };
  getKioskPassInfoView = () => {
    return (
      <>
        {this.state.isShowPassInfoSection ? (
          <>
            <DialogTitle id="simple-dialog-title" className="text-center">
              <FontAwesomeIcon
                className="modal-close"
                icon={faTimes}
                onClick={this.props.onClose}
              />
              <div id="headerText">Enter Your Pass Information</div>
            </DialogTitle>
            <DialogContent>
              {this.getYourDetails()}
              {!this.state.validation.success && (
                <div className="modal-error-message">
                  {this.state.validation.message}
                </div>
              )}
              <div style={{ marginLeft: "0px" }}>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => {
                    this.handleApplyClick(true);
                  }}
                >
                  Apply Discount
                </Button>
              </div>
              <div className="spacing-div"></div>
            </DialogContent>
            <DialogActions style={{ display: "table" }}></DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="simple-dialog-title" className="text-center">
              <FontAwesomeIcon
                className="modal-close"
                icon={faTimes}
                onClick={this.handleClosePassInfo}
              />
            </DialogTitle>
            {this.state.isCameraAvailable && (
              <div style={{ paddingBottom: 20 }}>
                <DynamsoftBarcodeScanner
                  getPassInfoByEncryptedText={this.getPassInfoByEncryptedText}
                  checkBarcodeReaderScanAgain={this.checkBarcodeReaderScanAgain}
                  onClose={this.handleClose}
                />
              </div>
            )}
            <div className="text-center">
              {this.state.isCameraAvailable && (
                <span className="message">Please Scan your pass here</span>
              )}
              <div>
                {this.state.isCameraAvailable && <div className="text">OR</div>}
                <span className="message" style={{ paddingBottom: 30 }}>
                  Please Swipe your card here
                </span>
              </div>
              <p
                className="linkText manual-enter-pass"
                onClick={this.handlePassInfoSection}
              >
                Having trouble swiping? Click here to enter it manually
              </p>
            </div>
          </>
        )}
      </>
    );
  };
  getNonKioskModeView = () => {
    return (
      <>
        {this.state.isShowPassInfoSection ? (
          <>
            <DialogTitle id="simple-dialog-title" className="text-center">
              <FontAwesomeIcon
                className="modal-close"
                icon={faTimes}
                onClick={this.props.onClose}
              />
              <div id="headerText">Enter Your Pass Information</div>
            </DialogTitle>
            <DialogContent>
              {this.getYourDetails()}
              {!this.state.validation.success && (
                <div className="modal-error-message">
                  {this.state.validation.message}
                </div>
              )}
              <div style={{ marginLeft: "0px" }}>
                <Button
                  variant="danger"
                  type="button"
                  onClick={() => {
                    this.handleApplyClick(true);
                  }}
                >
                  Apply Discount
                </Button>
              </div>
              <div className="spacing-div"></div>
            </DialogContent>
            <DialogActions style={{ display: "table" }}></DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="simple-dialog-title" className="text-center">
              <FontAwesomeIcon
                className="modal-close"
                icon={faTimes}
                onClick={this.handleClosePassInfo}
              />
            </DialogTitle>
            {this.state.isCameraAvailable && (
              <div style={{ paddingBottom: 20 }}>
                <DynamsoftBarcodeScanner
                  getPassInfoByEncryptedText={this.getPassInfoByEncryptedText}
                  checkBarcodeReaderScanAgain={this.checkBarcodeReaderScanAgain}
                  onClose={this.handleClose}
                />
              </div>
            )}
            <div className="text-center">
              {this.state.isCameraAvailable && (
                <span className="message">Please Scan your pass here</span>
              )}
              <p
                className="linkText manual-enter-pass"
                onClick={this.handlePassInfoSection}
              >
                Having trouble scanning? Click here to enter it manually
              </p>
            </div>
          </>
        )}
      </>
    );
  };
  render() {
    let { kioskSetting } = this.state;
    return (
      <Fragment>
        {this.state.toaster?.length === 1 && (
          <Toaster
            message={this.state.toaster[0].message}
            type={this.state.toaster[0].type}
            id={this.state.toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        <Spinner
          show={this.state.spinner.show}
          value={this.state.spinner}
        ></Spinner>
        <Dialog
          id="passInfoDialog"
          fullWidth={true}
          maxWidth={false}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.props.open}
          onEntering={this.handleOnEnter}
        >
          {kioskSetting && kioskSetting.IsKioskActive
            ? this.getKioskPassInfoView()
            : this.getNonKioskModeView()}
        </Dialog>
      </Fragment>
    );
  }
}
export default PassInfoDialog;
