import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Toaster(props) {
  const [state, setState] = React.useState({
    open: true,
    vertical: "top",
    horizontal: "right",
  });

  const typeConfig = {
    s: "success",
    w: "warning",
    i: "info",
    e: "error",
  };
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
    props.closeCallBack(props.id);
  };

  return (
    <div>    
      <Snackbar
        open={open}
        autoHideDuration={5000}
        key={vertical + horizontal}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={typeConfig[props.type]}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const showToasterMessage = (message, type, time = 5000) => {
  const id =
    new Date().toLocaleDateString().replaceAll("/", "") +
    new Date().toLocaleTimeString().replaceAll(":", "").replaceAll(" ", "");
  let toaster = [];
  toaster.push({
    type,
    message,
    id,
    time
  });
  return toaster;
};

export const ToasterHelper = {
  show: showToasterMessage
}