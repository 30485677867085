import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component, useReducer, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
// import { Redirect } from "react-router-dom";
import { storage } from "../../Utility/CartStorage";
import { formatPrice } from "../../Utility/Common";
import {
  CartItemKey,
  PassInfoKey,
  RestaurantDetailsByNodeId,
  OrderDetails,
  KIOSKSetting,
  CouponCodeKey,
} from "../../Utility/Config";
import { Storage } from "../../Utility/Storage";
import { getPickupTime } from "../../Utility/URLService";
import { post, get } from "../../Utility/HttpService";

const useForceRerender = () => useReducer((state) => !state, false)[1];
const QueueToolTip = (state) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <span ref={target} onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>

      <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            {state.restaurantDetails.rfndCondition}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

class OrderPlaced extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: JSON.parse(Storage.getItem("temp_" + CartItemKey)),
      passInfo: JSON.parse(Storage.getItem(PassInfoKey)),
      restaurantDetails: JSON.parse(Storage.getItem(RestaurantDetailsByNodeId)),
      orderDetails: JSON.parse(Storage.getItem(OrderDetails)),
      pickupTime: "",
      kioskSetting: JSON.parse(Storage.getItem(KIOSKSetting)),
      screenRefreshTime: 0,
      isRefreshScreen: false,
      confirmationSurveyCode: window.ENV.ConfirmationSurveyCode,
    };
  }

  componentWillUnmount() {
    //storage.reInitializeCart();
    this.state.isRefreshScreen && clearTimeout(this.confirmationTimer);
  }
  componentDidMount() {
    Storage.removeItem(CouponCodeKey);
    this.getPickupTime();
    console.log("orderDetails: ", JSON.parse(Storage.getItem(OrderDetails)));
    this.checkAndRedirectToMainPage();
  }
  getPickupTime = () => {
    const restaurantInfo = storage.getRestaurantInfo();
    get(getPickupTime + "&restaurantNodeId=" + restaurantInfo.nodeId.toString())
      .then((result) => result.data)
      .then((response) => {
        if (response && response.status) {
          let data = JSON.parse(response.data);
          this.setState({
            pickupTime: data.RestaurantPickupTime,
            isShowRestaurantWaitingTime: data.IsShowRestaurantWaitingTime,
          });
        }
      });
  };
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.Confirmation);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = () => {
    let redirectURL = Storage.getRedirectURL();
    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
  };
  render() {
    // if (this.state.isRefreshScreen) {
    //   return <Redirect to="/" />;
    // } else {
    let { orderDetails } = this.state;
    return (
      <div id="order-placed">
        {this.getHeaderDetails()}
        {/* To-Do: Pending */}
        {orderDetails && orderDetails.OrderAgainNumber && this.orderAgainLink()}
        {this.pickUpDetails()}
        {this.getOrderDetails()}
        {this.otherInformation()}
      </div>
    );
    // }
  }

  orderAgainLink = () => {
    debugger;
    let { orderDetails } = this.state;
    let orderAgainLink = `${window.location.origin}/orderAgain?token=${orderDetails.OrderAgainNumber}`;
    let orderAgainUrl = orderDetails.OrderAgainUrl.replaceAll(
      "%LINK%",
      orderAgainLink
    );
    if (orderDetails && orderDetails.OrderAgainNumber)
      return (
        <div
          className="orderAgainLinkText"
          dangerouslySetInnerHTML={{ __html: orderAgainUrl }}
        />
      );
    else return <></>;
  };
  getHeaderDetails = () => {
    return (
      <>
        <h1>{this.state.restaurantDetails.restaurantName}</h1>
        <p className="helping-text">Your order has been received</p>
      </>
    );
  };

  getMemberShipData = () => {};

  getCheckOrderStatusURL = () => {
    const { nodeId, salesPointName } = this.state.restaurantDetails;
    let url =
      window.ENV.FNBAppBaseURL +
      `orderStatus/${nodeId}/${this.state.orderDetails.OrderId}/${salesPointName}`;
    return url;
  };

  pickUpDetails() {
    //let checkStatusURL = ""
    return (
      <div className="pickup-details">
        <h2>Pickup Details</h2>
        {this.state.restaurantDetails.restaurant_reqEnd !== 1 && (
          <div>
            {/* <p className="mb-0">Your order will be ready:</p> */}
            <p className="readyBy bold">
              {this.state.isShowRestaurantWaitingTime
                ? this.state.restaurantWaitingTimeMessage
                : this.state.pickupTime &&
                  "Your order will be ready: " + this.state.pickupTime}
              <QueueToolTip restaurantDetails={this.state.restaurantDetails} />
            </p>
          </div>
        )}
        {!(
          this.state.kioskSetting && this.state.kioskSetting.IsKioskActive
        ) && (
          <a
            className="checkOrderStatus"
            rel="noopener noreferrer"
            target="_self"
            href={this.getCheckOrderStatusURL()}
          >
            Check Order Status
          </a>
        )}
        <p className="mb-0">Pickup Location:</p>
        <p className="helper-bold mb-0">
          {this.state.restaurantDetails.pickupDetails}
        </p>
        {!(
          this.state.kioskSetting && this.state.kioskSetting.IsKioskActive
        ) && (
          <p className="mt-2 red">
            <a
              className="red"
              href={this.state.restaurantDetails.directions}
              target="blank"
            >
              Get Directions
            </a>
          </p>
        )}
      </div>
    );
  }

  getOrderDetails() {
    return (
      <div className="order-details">
        <h2>Order Details</h2>

        {this.state.cartItems.foodItems.map((item, key) => {
          return (
            <>
              <div key={key} className="detail-section">
                <div className="heading-section">
                  <p>
                    {item.name} ({item.quantity})
                  </p>
                  <p>{formatPrice(item.actualPrice * item.quantity)}</p>
                </div>
                {this.getDiscountContainerForItem(item)}
                {item.customizations.map((customization, key) => {
                  return (
                    <div className="pl-2">
                      <p>{customization.name.replace("Select a", "")}:</p>
                      <div>
                        {customization.customizationOptions.map(
                          (option, key) => {
                            return (
                              <div className="pl-2 details" key={option.nodeId}>
                                <span>{option.name}</span>
                                {option.price >= 1 && (
                                  <span className="float-right price">
                                    {formatPrice(
                                      option.actualPrice * item.quantity
                                    )}
                                  </span>
                                )}
                                {this.getDiscountContainerForItem(option)}
                              </div>
                            );
                          }
                        )}
                      </div>
                      {customization.customizationOptions.length === 0 && (
                        <div className="pl-2 details">
                          <span>{customization.textBoxValue}</span>
                          <p></p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}

        <div className="total-section">
          <div>
            <p>Subtotal</p>
            <p>{this.calculateSubTotal()}</p>
          </div>
          {this.getDiscountContainerForTotal()}
          <div>
            <p>Sales Tax</p>
            <p>{this.calculateSalesTax()}</p>
          </div>
          {this.state.orderDetails &&
            this.state.orderDetails.ServiceChargeInfo &&
            this.state.orderDetails.ServiceChargeInfo.ServiceChargeValue > 0 && (
              <div>
                <p>Service Charge</p>
                <p>
                  {this.state.orderDetails.ServiceChargeInfo.ServiceChargeValue}
                </p>
              </div>
            )}
          <div>
            <p>Tip Amount</p>
            <p>{this.getTipAmount()}</p>
          </div>
          <div>
            <p>Total</p>
            <p>{formatPrice(this.calculateTotal())}</p>
          </div>
          {this.checkAndGetConfirmationSurvey()}
        </div>
      </div>
    );
  }
  checkAndGetConfirmationSurvey = () => {
    let { kioskSetting, confirmationSurveyCode } = this.state;
    if (!(kioskSetting && kioskSetting.IsKioskActive) && confirmationSurveyCode)
      return (
        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={{ __html: confirmationSurveyCode }}
        />
      );
    else return <></>;
  };
  getDiscountContainerForItem = (foodItem) => {
    if (
      foodItem.specialDiscount &&
      foodItem.specialDiscount.hasOwnProperty("discount") &&
      foodItem.specialDiscount.discount > 0
    ) {
      return (
        <div
          className="pl-2 details"
          key={foodItem.nodeId.toString() + "_discount"}
        >
          <span>Discount</span>
          <span className="float-right price">
            {formatPrice(foodItem.specialDiscount.discount)}
          </span>
          <p></p>
        </div>
      );
    } else {
      return null;
    }
  };

  getTipAmount = () => {
    return formatPrice(parseFloat(this.state.cartItems.tipAmount));
  };

  calculateSubTotal = () => {
    return formatPrice(this.state.cartItems.totalPriceWithoutTax);
  };

  calculateSalesTax = () => {
    return formatPrice(this.state.cartItems.totalSalesTax);
  };

  calculateTotal = () => {
    const info = this.state.cartItems;
    if (!info.totalDiscount) {
      info.totalDiscount = 0;
    }
    let total =
      info.totalPriceWithoutTax +
      info.totalSalesTax -
      info.totalDiscount +
      info.tipAmount;
    if (
      this.state.orderDetails &&
      this.state.orderDetails.ServiceChargeInfo &&
      this.state.orderDetails.ServiceChargeInfo.ServiceChargeValue > 0
    )
      total =
        total + this.state.orderDetails.ServiceChargeInfo.ServiceChargeValue;
    return total;
  };

  getDiscountContainerForTotal = () => {
    const info = this.state.cartItems;
    if (info.totalDiscount > 0) {
      return (
        <div>
          <p>Guest Discount</p>
          <p>
            {"-"}
            {formatPrice(info.totalDiscount)}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  otherInformation() {
    return (
      <div className="other-info">
        <h6>Order Date</h6>
        <p>
          {moment(this.state.orderDetails.OrderDate).format(
            "MM/DD/yyyy hh:mm a"
          )}
        </p>

        <h6>Order Number</h6>
        <p>{this.state.orderDetails.OrderId}</p>

        <h6>Check Number</h6>
        <p>{this.state.orderDetails.CheckNumber}</p>

        <h6>Your Information</h6>
        <p className="mb-0">
          {this.state.passInfo.firstName} {this.state.passInfo.lastName}
        </p>
        <p className="mb-0">{this.state.passInfo.emailId}</p>
        <p> {this.state.passInfo.phoneNumber}</p>

        <h6>Payment Details</h6>
        <p>{this.state.orderDetails.CreditCardText}</p>
        <p>{this.state.orderDetails.GiftCardText}</p>
      </div>
    );
  }
}

export default OrderPlaced;
