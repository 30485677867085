import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { formatPrice } from '../../Utility/Common';


class Footer extends Component {
  state = {};
  amount = 0;
  render() {
    return (
      <div className={this.getFooterClasses()}>
        {/* <Link to="/cart"> */}
          <Button>
            View Cart
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        {/* </Link> */}
        <div className="amount">{this.getTotalAmount()}</div>
      </div>
    );
  }

  getFooterClasses() {
    let classes = "footer";
    classes += this.props.isDisplayFooter ? " show" : "";
    return classes;
  }

  getTotalAmount() {
    return formatPrice(this.props.amount); // (this.amount += Number(this.props.amount));
  }
}

export default Footer;
