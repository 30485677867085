import React, { Component, Fragment } from "react";
import Header from "../Header/Header";
import AppRoutingComponent from "../../AppRouting";
import { Container } from "react-bootstrap";
import SideNav from "../SideNav/SideNav";
import { get } from "../../Utility/HttpService";
import { getRestaurantsURL } from "../../Utility/URLService";
import { AppInfo } from "../../Models/AppInfo";
import {AppName} from "../../Utility/Config";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appInfo: {},
    };
    this.getAppinformations();
  }

  componentDidMount(){
    document.title = AppName;
  }

  getAppinformations = () => {
    return get(getRestaurantsURL)
      .then((result) => result.data)
      .then((data: AppInfo) => {        
        delete data.restaurants;
        const appInfo = data;
        this.setState({ appInfo });
      });
  };

  render() {
    return (
      <Container fluid>
        <div className="desktop-header">
          <Header />
        </div>
        <div className="layout">
          <SideNav appInfo={this.state.appInfo} />
          <div className="main">
            <AppRoutingComponent />
          </div>
        </div>
      </Container>
    );
  }
}

export default Home;
