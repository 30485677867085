import React, { Component } from "react";
import logo from "../../assets/images/logo.png";
import { Navbar } from "react-bootstrap";
import { KIOSKSetting } from "../../Utility/Config";
import { Storage as _storage } from "../../Utility/Storage";
import { storage } from "../../Utility/CartStorage";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleHeader = () => {
    var kioskSetting = JSON.parse(_storage.getItem(KIOSKSetting));
    let redirectUrl = "/";
    if (kioskSetting && kioskSetting.IsKioskActive) {
      redirectUrl = kioskSetting.RedirectUrl;
    }
    //this.removeCartItemsFromStorage();
    window.location.href = `/FNBApp.Web.UI${redirectUrl}`;
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
    storage.reInitCartWhenCartIdInvalid();
  };
  render() {
    return (
      <div id="header">
        <Navbar>
          <Navbar.Brand onClick={this.handleHeader}>
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="Restaurant logo"
            />
          </Navbar.Brand>
        </Navbar>

        <div className="welcome-section">
          {this.props.appInfo && (
            <>
              <h2>
                {this.props.appInfo.welcomeText
                  ? this.props.appInfo.welcomeText
                  : ""}
              </h2>
              <h6>
                {this.props.appInfo.description
                  ? this.props.appInfo.description
                  : ""}
              </h6>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Header;
