import {
  CartItemKey,
  CartStorage_ExpirationMinutes,
  RestaurantDetailsByNodeId,
  SMSInfoKey,
} from "./Config";
import { Storage } from "./Storage";
import { get, post } from "./HttpService";
import {
  initializeCartId,
  verifyCartItem,
  removeCartItem,
  rollBackCartItemDetails,
} from "./URLService";
import { CommonResponse } from "../Models/CommonResponse";

const getRestaurantInfo = () => {
  const info = Storage.getItem(RestaurantDetailsByNodeId);
  if (info) {
    let _info = JSON.parse(info);
    return {
      ..._info,
    };
  } else {
    return null;
  }
};

const updateMenuNodeIdIntoRestaurantInfo = (menuNodeId) => {
  const info = Storage.getItem(RestaurantDetailsByNodeId);
  if (info) {
    let _info = JSON.parse(info);
    _info.menuNodeId = menuNodeId;
    Storage.setItem(RestaurantDetailsByNodeId, JSON.stringify(_info));
  }
};

var checkCartItemsExpiration = () => {
  let isExpired = false;
  let foodItem = Storage.getItem(CartItemKey);
  if (foodItem) {
    foodItem = JSON.parse(foodItem);
    let lastUpdatedOn = new Date(foodItem.lastUpdatedOn);
    //console.log("***********************************************");
    //console.log("last update", lastUpdatedOn);
    lastUpdatedOn.setMinutes(
      lastUpdatedOn.getMinutes() + CartStorage_ExpirationMinutes
    );
    //console.log("expiring date", lastUpdatedOn);
    if (lastUpdatedOn >= new Date()) {
      //console.log("cart available");
      isExpired = false;
    } else {
      //console.log("cart expired");
      isExpired = true;
    }
  } else isExpired = false;
  //console.log("***********************************************");
  return isExpired;
};

var updateCartItemLastUpdatedOn = () => {
  let foodItem = Storage.getItem(CartItemKey);
  if (foodItem) {
    foodItem = JSON.parse(foodItem);
    foodItem.lastUpdatedOn = Date.now();
    foodItem = JSON.stringify(foodItem);
    Storage.setItem(CartItemKey, foodItem);
  }
};

var calculateCartFigures = (cartItems) => {
  let totalPrice = 0,
    totalPriceWithoutTax = 0,
    totalSalesTax = 0,
    totalDiscount = 0,
    totalQuantityCount = 0;
  if (cartItems.foodItems.length > 0) {
    cartItems.foodItems.forEach((foodItem) => {
      totalQuantityCount += foodItem.quantity;
      totalPrice += foodItem.price * foodItem.quantity;
      if (foodItem.actualPrice > 0) {
        totalPriceWithoutTax += foodItem.actualPrice * foodItem.quantity;
      }
      if (
        foodItem.specialDiscount &&
        foodItem.specialDiscount.hasOwnProperty("discount") &&
        foodItem.specialDiscount.discount > 0
      ) {
        totalDiscount +=
          foodItem.specialDiscount.discount > 0
            ? foodItem.specialDiscount.discount
            : 0;
        let salesTax =
          foodItem.specialDiscount.discountSalesTax > 0
            ? foodItem.specialDiscount.discountSalesTax
            : 0;
        totalSalesTax += salesTax;
      } else {
        if (foodItem.salesTax > 0 && foodItem.actualPrice > 0) {
          totalSalesTax += foodItem.salesTax * foodItem.quantity;
        }
      }
      if (
        foodItem.couponDiscount &&
        foodItem.couponDiscount.hasOwnProperty("discount") &&
        foodItem.couponDiscount.discount > 0
      ) {
        totalDiscount +=
          foodItem.couponDiscount.discount > 0
            ? foodItem.couponDiscount.discount
            : 0;
      }

      foodItem.customizations.forEach((customization) => {
        if (customization.customizationOptions.length > 0) {
          customization.customizationOptions.forEach((option) => {
            if (option.actualPrice > 0) {
              totalPrice += Number(option.price) * foodItem.quantity;
              if (option.actualPrice > 0) {
                if (
                  option.couponDiscount &&
                  option.couponDiscount.hasOwnProperty("discount") &&
                  option.couponDiscount.discount > 0
                ) {
                  totalPriceWithoutTax += Number(
                    option.couponDiscount.actualPrice
                  );
                } else {
                  totalPriceWithoutTax +=
                    Number(option.actualPrice) * foodItem.quantity;
                }
              }
            }
            if (
              option.specialDiscount &&
              option.specialDiscount.hasOwnProperty("discount") &&
              option.specialDiscount.discount > 0
            ) {
              totalDiscount +=
                option.specialDiscount.discount > 0
                  ? option.specialDiscount.discount
                  : 0;
              let salesTax =
                option.specialDiscount.discountSalesTax > 0
                  ? option.specialDiscount.discountSalesTax
                  : 0;
              totalSalesTax += salesTax;
            } else if (
              option.couponDiscount &&
              option.couponDiscount.hasOwnProperty("discount") &&
              option.couponDiscount.discount > 0
            ) {
              totalDiscount +=
                option.couponDiscount.discount > 0
                  ? option.couponDiscount.discount
                  : 0;
              let salesTax =
                option.couponDiscount.discountSalesTax > 0
                  ? option.couponDiscount.discountSalesTax
                  : 0;
              totalSalesTax += salesTax;
            } else {
              if (option.salesTax > 0 && option.actualPrice > 0) {
                totalSalesTax += Number(option.salesTax) * foodItem.quantity;
              }
            }
          });
        }
      });
    });
  }
  cartItems.totalPriceWithoutTax = totalPriceWithoutTax;
  cartItems.totalPrice = totalPrice;
  cartItems.totalSalesTax = totalSalesTax;
  cartItems.totalDiscount = totalDiscount;
  cartItems.totalQuantityCount = totalQuantityCount;
  return cartItems;
};

var updateTipAmount = (amount) => {
  let cartItems = Storage.getItem(CartItemKey);
  let tipAmount =
    amount !== undefined && amount !== "" ? parseFloat(amount) : 0;
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    cartItems.tipAmount = tipAmount;
    Storage.setItem(CartItemKey, JSON.stringify(cartItems));
  }
};

const updateGiftCardInfo = (giftCards) => {
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    cartItems.giftCards = giftCards;
    Storage.setItem(CartItemKey, JSON.stringify(cartItems));
  }
};

const initializeCart = (foodItem = null, customizations = []) => {
  let cartItems = Storage.getItem(CartItemKey);
  if (!cartItems) {
    get(initializeCartId)
      .then((result) => result.data)
      .then((data) => {
        cartItems = initCartJSON(data);
        Storage.setItem(CartItemKey, JSON.stringify(cartItems));
        if (foodItem != null) {
          addFooditemIntoCart(foodItem, customizations);
        }
      });
  }
};

const initCartJSON = (cartId) => {
  let cartItems = {
    cartId,
    employeePurchase: false,
    totalPrice: 0,
    totalPriceWithoutTax: 0,
    totalSalesTax: 0,
    tipAmount: 0,
    totalDiscount: 0,
    totalQuantityCount: 0,
    foodItems: [],
    lastUpdatedOn: Date.now(),
    giftCardInfo: {
      balance: 0,
      cardNumber: "",
      pin: "",
    },
    restaurantNodeId: 0,
  };
  return cartItems;
};

var addFooditemIntoCart = (foodItem, customizations = []) => {
  let nodeId = foodItem.nodeId;
  let cartItems = Storage.getItem(CartItemKey);
  if (!cartItems) {
    initializeCart(foodItem, customizations);
    return;
  }
  cartItems = JSON.parse(cartItems);

  // cartItems.foodItems = cartItems.foodItems.filter((item) => {
  //   return item.nodeId !== nodeId;
  // });

  if (customizations && customizations.length > 0) {
    foodItem.customizations = customizations;
  } else {
    foodItem.customizations = [];
  }
  cartItems.foodItems.push(foodItem);
  cartItems = calculateCartFigures(cartItems);
  cartItems.lastUpdatedOn = Date.now();

  // Set Restaurant NodeId. Used for single restaurant items add
  cartItems.restaurantNodeId = getRestaurantInfo().nodeId;

  return Storage.setItem(CartItemKey, JSON.stringify(cartItems));
};

var removeFoodItemFromCart = (nodeId, id) => {
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    let fieldName = "nodeId";
    if (id) {
      fieldName = "id";
      nodeId = id.toString();
    }
    cartItems.foodItems = cartItems.foodItems.filter((foodItem) => {
      return foodItem[fieldName] !== nodeId;
    });
    cartItems = calculateCartFigures(cartItems);
    cartItems.lastUpdatedOn = Date.now();

    // when we remove the item from different restaurant. We need to handle
    if (cartItems.foodItems.length === 0) {
      // Set Restaurant NodeId. Used for single restaurant items add
      cartItems.restaurantNodeId = getRestaurantInfo().nodeId;
    }

    Storage.setItem(CartItemKey, JSON.stringify(cartItems));
  }
  return true;
};

var getCartInfo = () => {
  let totalPrice = 0,
    totalPriceWithoutTax = 0,
    totalCount = 0,
    totalSalesTax = 0,
    tipAmount = 0,
    totalDiscount = 0,
    totalQuantityCount = 0,
    cartId = "",
    restaurantNodeId = 0;
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    cartId = cartItems.cartId;
    totalPrice = cartItems.totalPrice;
    totalCount = cartItems.foodItems.length;
    totalPriceWithoutTax = cartItems.totalPriceWithoutTax;
    totalSalesTax = cartItems.totalSalesTax;
    tipAmount = cartItems.tipAmount;
    totalDiscount = cartItems.totalDiscount;
    totalQuantityCount = cartItems.totalQuantityCount;
    restaurantNodeId = cartItems.restaurantNodeId;
  }
  return {
    cartId,
    totalPrice,
    totalCount,
    totalPriceWithoutTax,
    totalSalesTax,
    tipAmount,
    totalDiscount,
    totalQuantityCount,
    restaurantNodeId,
  };
};

var getFoodItemFromStorage = (nodeId, id) => {
  let foodItem = null;
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    let fieldName = "nodeId";
    if (id) {
      fieldName = "id";
      nodeId = id.toString();
    }
    foodItem = cartItems.foodItems.filter((foodItem) => {
      return foodItem[fieldName].toString() === nodeId.toString();
    });
  }
  return foodItem;
};

var getAllFoodItemFromStorage = () => {
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
  }
  return cartItems;
};

var getSMSInformation = () => {
  let smsInfo = Storage.getItem(SMSInfoKey);
  if (smsInfo) {
    smsInfo = JSON.parse(smsInfo);
  }
  return smsInfo;
};

const reInitCartWhenCartIdInvalid = () => {
  Storage.setItem(CartItemKey, "");
  return true;
};

const removeAllfromStorage = () => {
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    const cartItem = {
      cartId: cartItems.cartId,
    };
    post(rollBackCartItemDetails, cartItem)
      .then((result) => result.data)
      .then((data) => {
        if (data) {
          cartItems = initCartJSON(cartItems.cartId);
          Storage.setItem(CartItemKey, JSON.stringify(cartItems));
        }
      });
  }
};

const reInitializeCart = () => {
  let cartItems = Storage.getItem(CartItemKey);
  if (cartItems) {
    cartItems = JSON.parse(cartItems);
    cartItems = initCartJSON(cartItems.cartId);
    Storage.setItem(CartItemKey, JSON.stringify(cartItems));
  }
};

const updateFoodItem = (foodItem, customizations = []) => {
  foodItem.id = foodItem.id ? foodItem.id : Date.parse(new Date()).toString();
  let cartItems = getAllFoodItemFromStorage();
  if (cartItems) {
    if (customizations && customizations.length > 0) {
      foodItem.customizations = customizations;
    } else {
      foodItem.customizations = [];
    }
    let _index = -1;
    if (foodItem.customizations.length === 0) {
      cartItems.foodItems.forEach((item, index) => {
        if (
          item.customizations.length === 0 &&
          item.nodeId === foodItem.nodeId
        ) {
          _index = index;
        }
      });
    } else {
      _index = cartItems.foodItems.map((e) => e.id).indexOf(foodItem.id);
    }
    if (_index >= 0) {
      cartItems.foodItems.splice(_index, 1, foodItem);
      cartItems = calculateCartFigures(cartItems);
      cartItems.lastUpdatedOn = Date.now();
      Storage.setItem(CartItemKey, JSON.stringify(cartItems));
    } else {
      addFooditemIntoCart(foodItem, customizations);
    }
  } else {
    addFooditemIntoCart(foodItem, customizations);
  }
};

var removeFoodItemWithMax4Sale = (
  foodItem,
  successCallBack,
  failureCallBack
) => {
  const info = storage.info();
  if (info.cartId !== "") {
    const cartItem = {
      cartId: info.cartId,
      department: foodItem.department,
      category: foodItem.category,
      item: foodItem.item,
      quantity: foodItem.quantity,
    };
    post(removeCartItem, cartItem)
      .then((result) => result.data)
      .then((data) => {
        if (data.status) {
          successCallBack(foodItem);
        } else {
          failureCallBack(foodItem, data);
        }
      });
  }
};

var verifyFoodItemWithMax4Sale = (
  foodItem,
  quantity,
  successCallBack,
  failureCallBack
) => {
  const info = storage.info();
  if (info.cartId !== "") {
    const cartItem = {
      cartId: info.cartId,
      department: foodItem.department,
      category: foodItem.category,
      item: foodItem.item,
      quantity,
    };
    post(verifyCartItem, cartItem)
      .then((result) => result.data)
      .then((data) => {
        if (data.status) {
          successCallBack(foodItem, quantity);
        } else {
          failureCallBack(data);
        }
      });
  }
};

export const storage = {
  add: addFooditemIntoCart,
  remove: removeFoodItemFromCart,
  update: updateFoodItem,
  info: getCartInfo,
  updateCartItemLastUpdatedOn,
  checkCartItemsExpiration,
  getFoodItem: getFoodItemFromStorage,
  getAllFoodItems: getAllFoodItemFromStorage,
  removeAll: removeAllfromStorage,
  updateTipAmount,
  calculateCartFigures,
  updateGiftCardInfo,
  initializeCart,
  verifyFoodItemWithMax4Sale,
  removeFoodItemWithMax4Sale,
  reInitializeCart,
  reInitCartWhenCartIdInvalid,
  getRestaurantInfo,
  updateMenuNodeId: updateMenuNodeIdIntoRestaurantInfo,
  getSMSInfo: getSMSInformation,
};
