export const ItemTreeRoot = window.ENV.ItemTreeRoot; // Sirusware Root Item Tree
export const FNBAppBaseURL = window.ENV.FNBAppBaseURL;
export const Required_CryptoSecurity = window.ENV.Required_CryptoSecurity;
export const CartStorage_ExpirationMinutes =
  window.ENV.CartStorage_ExpirationMinutes;
export const ExpiryCheck_IntervalMinutes =
  window.ENV.ExpiryCheck_IntervalMinutes;
export const Expiration_CountDownTimerMinutes =
  window.ENV.Expiration_CountDownTimerMinutes;
export const CurrencySymbol = window.ENV.CurrencySymbol;
export const AppName = window.ENV.App_Name;

export const UserId = "1001";
export const CryptoSecurityKey = "MCP FNB VERSION 01";
export const SalesCryptoSecurityKey = "MCPPassword$%^1243mnc";
export const CartItemKey = UserId.toString() + "_cartItems";
export const MembershipKey = UserId.toString() + "_membership";
export const PassInfoKey = UserId.toString() + "_passInfo";
export const CouponCodeKey = UserId.toString() + "_couponcodekey";
export const RestaurantDetailsByNodeId = UserId.toString() + "_restaurantInfo";
export const OrderDetails = UserId.toString() + "_orderDetails";
export const SMSInfoKey = UserId.toString() + "_smsInfo";
export const KIOSKSetting = UserId.toString() + "_kioskSetting";
export const TableNumber = UserId.toString() + "_tableNumber";
export const VerifyOrderKey = UserId.toString() + "_verifyOrderInfo";
export const OrderAgainNumber = UserId.toString() + "_orderAgainNumber";
export const IsFromCouponToken = UserId.toString() + "_isFromCouponToken";
export const IsFromGuestToken = UserId.toString() + "_isFromGuestToken";
