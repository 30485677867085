import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";

class SideNav extends Component {
  state = {
    list: [
      {
        name: "restaurant",
        text: "Restaurant Maps",
      },
      {
        name: "delivery",
        text: "Delivery",
      },
      {
        name: "reviews",
        text: "Reviews",
      },
      {
        name: "faqs",
        text: "FAQs",
      },
    ],
  };

  render() {
    // console.log("sideNav prop", this.props)
    return (
      <div id="sideNav">
        <h2>
          {this.props.appInfo.welcomeText ? this.props.appInfo.welcomeText : ""}
        </h2>
        <h6>
          {this.props.appInfo.description ? this.props.appInfo.description : ""}
        </h6>

        {/* <ListGroup variant="flush">
          {this.state.list.map((item, index) => {
            return (
              <ListGroup.Item key={index}>
                <img
                  src={require(`../../assets/images/${item.name}.svg`)}
                  alt="restaurant map"
                />{" "}
                {item.text}
              </ListGroup.Item>
            );
          })}
        </ListGroup> */}
      </div>
    );
  }
}

export default SideNav;
