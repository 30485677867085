import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "react-bootstrap";
import { formatPrice, roundedGiftCardAmount } from "../../Utility/Common";

export default function UseGiftCard(props) {
  return (
    <Dialog
      id="giftcardDialog"
      fullWidth={true}
      maxWidth={false}
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open
    >
      <DialogTitle id="simple-dialog-title">Use Gift Card</DialogTitle>
      <DialogContent>
        <p className="content">
          The gift card you have entered has a balance of{" "}
          {formatPrice(roundedGiftCardAmount(props.balance))}
        </p>
      </DialogContent>
      <DialogActions className="rounded-btns">
        <Button
          variant="danger"
          type="button"
          onClick={props.handleUseGiftCardClick}
        >
          Use Gift Card
        </Button>
        <Button variant="default" type="button" onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
