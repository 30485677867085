import React, { Component } from "react";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";

class BarcodeReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toaster: [],
    };
  }
  // componentDidMount = () => {
  //   this.handleScan();
  // };
  handleScan = (data) => {
    //For Testing
    // let text = "PXXD7JCS9C";
    // let text = "PXX704KYWH";
    let text = data.text;
    if (text && this.props.checkBarcodeReaderScanAgain()) {
      let passinitial = text.substring(0, 1).toLowerCase();
      if (passinitial === "a" || passinitial === "p") {
        this.props.getPassInfoByEncryptedText(text, false);
      } else {
        this.showToasterMessage("Please scan valid pass.", "w");
      }
    }
  };
  handleError = () => {
    // console.log("camera data not found");
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };
  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  render() {
    return (
      <>
        {this.state.toaster?.length === 1 && (
          <Toaster
            message={this.state.toaster[0].message}
            type={this.state.toaster[0].type}
            id={this.state.toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        <div>
          <BarcodeScannerComponent
            width="100%"
            height="100%"
            onUpdate={(err, result) => {
              if (result) this.handleScan(result);
              else this.handleError();
            }}
          />
        </div>
      </>
    );
  }
}

export default BarcodeReader;
