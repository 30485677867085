import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Home from "./components/Home/Home";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: window.ENV.GTMId
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render([<Home />], document.getElementById("root"));
