import React, { Component, Fragment } from "react";
import waiticon from "../../assets/images/waiticon.gif";
import { post } from "../../Utility/HttpService";
import { checkOrderStatus } from "../../Utility/URLService";
import { KIOSKSetting } from "../../Utility/Config";
import { storage } from "../../Utility/CartStorage";
import { Storage } from "../../Utility/Storage";
class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kioskSetting: JSON.parse(Storage.getItem(KIOSKSetting)),
      screenRefreshTime: 0,
      isRefreshScreen: false,
      request: {
        restaurantNodeId: props.match.params.restaurantNodeId,
        orderId: props.match.params.orderId,
        salesPointName: props.match.params.salesPointName
      },
      orderInfo: {
        firstName: "",
        lastName: "",
        pickUpDetails: "",
        directionLink: "",
        orderStatus: "",
        orderNumber: "",
        pickupTime: "",
      },
    };
    this.getOrderStatus();
  }

  getOrderStatus = () => {
    const request = this.state.request;
    post(checkOrderStatus, request)
      .then((result) => result.data)
      .then((data) => {
        const orderInfo = data;
        this.setState({ orderInfo });
      });
  };
  componentWillUnmount() {
    this.state.isRefreshScreen && clearTimeout(this.confirmationTimer);
  }
  componentDidMount() {
    this.checkAndRedirectToMainPage();
  }
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.Confirmation);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = () => {
    let redirectURL = Storage.getRedirectURL();
    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
  };

  render() {
    return (
      <div id="orderScreen" className="background">
        {this.state.orderInfo.orderStatus === "" &&
          this.getOrderPlacedInformation()}
        {this.state.orderInfo.orderStatus !== "" &&
          this.getReadyForPickupInformation()}
      </div>
    );
  }

  getOrderPlacedInformation = () => {
    return (
      <div className="not-ready">
        <img width="100%" src={waiticon} alt="Fork Knife Clock" />
        <div className="text-center">
          <p>Your Order will be ready:</p>
          {/* <p>{this.state.orderInfo.pickupTime}</p> */}
          <p>Check back soon!</p>
        </div>
      </div>
    );
  };
  getReadyForPickupInformation = () => {
    return (
      <Fragment>
        <div className="ready">
          <h1>Order #{this.state.orderInfo.orderNumber}</h1>
          <h1>
            {this.state.orderInfo.firstName} {this.state.orderInfo.lastName}
          </h1>
          <h1 className="ready-text">Ready For Pickup!</h1>
        </div>
        {/* <p className="content">
          Instruction or more information if needed go here. Veggie sunt bona
          vobis, proinde vos postulo esse
        </p> */}
        <div className="address">
          <p>Pickup Location:</p>
          <p>{this.state.orderInfo.pickUpDetails}</p>
          {this.state.orderInfo.directionLink && (
            <a
              className="get-direction"
              rel="noopener noreferrer"
              target="_blank"
              href={this.state.orderInfo.directionLink}
            >
              Get Directions
            </a>
          )}
        </div>
      </Fragment>
    );
  };
}
export default OrderStatus;
