import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import React, { Component } from "react";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";
import "../../assets/scss/dynamsoftBarcodeScanner.css";
import "../../dbr"; // import side effects. The license, engineResourcePath, so on.
import { BarcodeReader } from "dynamsoft-javascript-barcode";

class DynamsoftBarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toaster: [],
    };
    this.pScanner = null;
    this.elRef = React.createRef();
  }
  async componentDidMount() {
    try {
      await BarcodeReader.loadWasm();
      const scanner = await (this.pScanner = BarcodeScanner.createInstance());
      await scanner.setUIElement(this.elRef.current);
      scanner.onFrameRead = (results) => {
        for (let result of results) {
          console.log(result.barcodeText);
        }
      };
      scanner.onUniqueRead = (txt, result) => {
        this.handleScan(txt);
      };
      let runtimeSettings = await scanner.getRuntimeSettings();
      //Filter only 1D barcodes
      runtimeSettings.barcodeFormatIds = 3147775;
      await scanner.updateRuntimeSettings(runtimeSettings);

      await scanner.updateRuntimeSettings(`coverage`);
      await scanner.open();
      await scanner.setZoom(400);
      await scanner.setFrameRate(10);
      await scanner.setFocus(5);
      //For Testing
      //this.handleScan("test");
    } catch (ex) {
      let errMsg;
      if (ex.message.includes("network connection error")) {
        errMsg =
          "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
    }
  }
  async componentWillUnmount() {
    if (this.pScanner) {
      (await this.pScanner).destroyContext();
      console.log("BarcodeScanner Component Unmount");
    }
  }
  shouldComponentUpdate() {
    // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
    return false;
  }
  handleScan = (text) => {
    //For Testing
    //text = "PXX704KYWH";
    let passinitial = text.substring(0, 1).toLowerCase();
    if (passinitial === "a" || passinitial === "p") {
      this.props.getPassInfoByEncryptedText(text, false);
    } else {
      this.showToasterMessage("Please scan valid pass.", "w");
    }
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };
  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  render() {
    return (
      <>
        {this.state.toaster?.length === 1 && (
          <Toaster
            message={this.state.toaster[0].message}
            type={this.state.toaster[0].type}
            id={this.state.toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        <div>
          <div ref={this.elRef} className="component-barcode-scanner">
            {/* <svg
              className="dce-bg-camera"
              style={{ display: "none" }}
              viewBox="0 0 2048 1792"
            >
              <path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path>
            </svg> */}
            <div className="dce-video-container"></div>
            <div className="dce-scanarea">
              {/* <div className="dce-scanlight" style={{ display: "none" }}></div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DynamsoftBarcodeScanner;
