import axios from "axios";
import { cryptoJS } from "./Common";
import { Storage as _storage } from "./Storage";
const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Content-type": "application/json"
    // ,
    // "Authorization": "Bearer "+ getToken()
}
export function getToken() {
    let tokenKey = cryptoJS.encryption('token');
    let token =  _storage.getItem('token');
    return token;
}

export function get(url: string, responseType?: string) {
    if(responseType !== null && responseType !== undefined){
        return axios.get(url, {responseType: responseType});
    }
    return axios.get(url,{headers: headers});
}

export function post(url: string, data?: any, token = '') {
    let head = headers;
    head["Authorization"]  = "Bearer "+ token;
    return axios.post(url,data, {headers: head});
}

export function put(url: string, data?: any) {
    return axios.put(url,data, {headers: headers});
}