import React, { Component } from "react";
import Spinner from "../../Utility/Spinner";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";
import { checkOrderAgain } from "../../Utility/URLService";
import { get } from "../../Utility/HttpService";
import { Storage as _storage } from "../../Utility/Storage";
import {
  CartItemKey,
  OrderDetails,
  PassInfoKey,
  RestaurantDetailsByNodeId,
  OrderAgainNumber,
} from "../../Utility/Config";

class OrderAgain extends Component {
  constructor() {
    super();
    this.state = {
      toaster: [],
      spinner: {
        show: true,
      },
      message: "Please wait your order is being verified",
      orderAgainToken: this.getOrderAgainToken(),
    };
  }
  getOrderAgainToken = () => {
    let token = "";
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams) {
        token = queryParams.get("token");
      }
    }
    return token;
  };
  componentDidMount() {
    this.CheckOrderAgain();
  }
  CheckOrderAgain = () => {
    let { orderAgainToken } = this.state;
    if (orderAgainToken) {
      get(`${checkOrderAgain}${orderAgainToken}`)
        .then((result) => result.data)
        .then((data) => {
          if (data.status) {
            var response = JSON.parse(data.data);
            _storage.setItem(CartItemKey, JSON.stringify(response.CartItems));
            _storage.setItem(
              OrderDetails,
              JSON.stringify(response.OrderDetailsResponse)
            );
            _storage.setItem(
              PassInfoKey,
              JSON.stringify(response.PassInformation)
            );
            _storage.setItem(
              RestaurantDetailsByNodeId,
              JSON.stringify(response.RestaurantDetails)
            );
            _storage.setItem(OrderAgainNumber, JSON.stringify(orderAgainToken));
            let restaurantNodeId = response.CartItems.restaurantNodeId;
            this.props.history.push("/pickup/" + restaurantNodeId);
          } else {
            this.showToasterMessage(data.message, "e");
            setTimeout(() => {
              this.props.history.push("/");
            }, 5000);
          }
          this.showSpinner(false);
        })
        .catch((error) => {});
    } else {
      this.showSpinner(false);
      this.showToasterMessage("Token Expired", "e");
    }
  };
  showSpinner = (show) => {
    let { spinner } = this.state;
    spinner.show = show;
    this.setState({ spinner });
  };
  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };
  render() {
    let { spinner, toaster, message } = this.state;
    return (
      <div className="p-20">
        <Spinner show={spinner.show} value={spinner}></Spinner>
        {toaster?.length === 1 && (
          <Toaster
            message={toaster[0].message}
            type={toaster[0].type}
            id={toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        <p className="cen-div">{message}</p>
      </div>
    );
  }
}

export default OrderAgain;
