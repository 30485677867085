import {
  CurrencySymbol,
  CryptoSecurityKey,
  SalesCryptoSecurityKey,
} from "../Utility/Config";
import CryptoJS from "crypto-js";

export const formatPrice = (price) => {
  if (price) {
    return CurrencySymbol + "" + Number(price).toFixed(2);
  } else {
    return "";
  }
};
export const roundedGiftCardAmount = (amount) => {
  return amount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
};

const encryption = (content) => {
  return CryptoJS.AES.encrypt(content, CryptoSecurityKey).toString();
};

const encryptionWithCBCMode = (text) => {
  // let key = SalesCryptoSecurityKey;
  // let iv = CryptoJS.enc.Utf8.parse(SalesCryptoSecurityKey.substring(SalesCryptoSecurityKey.length - 16));
  // if (text === '' || text === undefined || text === null) {
  //   return text;
  // }

  // const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), SalesCryptoSecurityKey,
  // {
  //     keySize: 128 / 8,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  // });
  // return encrypted.toString();

  if (text === "" || text === undefined || text === null) {
    return text;
  }
  let keys = btoa(SalesCryptoSecurityKey);
  // key = CryptoJS.enc.Utf8.parse(this.keys.substring(0, 16));
  let iv = CryptoJS.enc.Utf8.parse(keys.substring(keys.length - 16));
  const key = CryptoJS.enc.Utf8.parse(keys.substring(0, 16)); // this.generateKey(additionalkey);
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(text.toString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return encrypted.toString();
};

const decryption = (cipherText) => {
  let bytes = CryptoJS.AES.decrypt(cipherText, CryptoSecurityKey);
  let result = bytes.toString(CryptoJS.enc.Utf8);
  return result.toString();
};

const protectEmail = (email) => {
  var avg, splitted, part1, part2;
  splitted = email.split("@");
  part1 = splitted[0];
  avg = part1.length / 2 + 1;
  part1 = part1.substring(0, part1.length - avg);
  part2 = splitted[1];
  return part1 + "...@" + part2;
};

const capitalizeFirstLetter = (str) => {
  let capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return capitalized;
};

export const cryptoJS = {
  encryption,
  decryption,
  encryptionWithCBCMode,
};

export const commonHelper = {
  protectEmail,
  capitalizeFirstLetter,
};
