import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";
import CountDownTimer from "./CountDownTimer";
import { Expiration_CountDownTimerMinutes } from "./Config";
import { storage } from "./CartStorage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CartExpirationDialog(props) {
  const { visible, closeEventCallBack, cartRemoval } = props.modal;
  const [show, setShow] = React.useState(visible);
  const [isTimerComplete, setIsTimerComplete] = React.useState(cartRemoval ? true: false);
 
  const handleClose = () => {
    setShow(false);
    if (!isTimerComplete) {
      storage.updateCartItemLastUpdatedOn();
      closeEventCallBack("Continue");
    }else{
      closeEventCallBack("Expired");
    }    
  };

  const removeCartItemsFromStorage = () => {
    storage.removeAll();
  };

  const timerCompleteEvent = () => {
    removeCartItemsFromStorage();
    setIsTimerComplete(true);
    closeEventCallBack("Expired");
  };

  const handleButtonText = () => {
    if (isTimerComplete) {
      return <>Continue</>;
    } else {
      return <>Continue With Your Order</>;
    }
  };

  const handleContent = () => {
    if (isTimerComplete) {
      return <>Due to inactivity, your cart has been cleared.</>;
    } else {
      return (
        <>
          Due to inactivity, your cart will be cleared out in{" "}
          {Expiration_CountDownTimerMinutes} minutes.
        </>
      );
    }
  };

  return (
    <div>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        id="dialog"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {isTimerComplete ? "Oops!" : "Are you still there?"}
        </DialogTitle>
        <DialogContent>
          {!isTimerComplete && (
            <Button variant="contained">
              <CountDownTimer
                minutes={Expiration_CountDownTimerMinutes}
                timerCompleteEvent={timerCompleteEvent}
              />
            </Button>
          )}
          <DialogContentText id="alert-dialog-slide-description">
           {handleContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
            }}
          >           
            { handleButtonText()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
