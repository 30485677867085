import React, { Component } from "react";

export default class CountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: "00",
      minutes: props.minutes,
    };    
  }
  componentDidMount() {
    this.secondsTimer = setInterval(() => {
        if(Number(this.state.seconds) === 0 && Number(this.state.minutes) === 0 ){
           clearInterval(this.secondsTimer);
           this.props.timerCompleteEvent();
        }else{
            let minutes = Number(this.state.minutes) ;
            let seconds = (Number(this.state.seconds) - 1);
            if(seconds === -1){ seconds = 59; minutes -= 1; }  
            seconds = (seconds < 10)  ? "0" + seconds : seconds.toString(); 
            minutes = (minutes < 10)  ? "0" + minutes : minutes.toString(); 
            this.setState({ seconds, minutes });
        }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.secondsTimer);
  }

  render() {           
    return (      
      this.state.minutes.toString() + ":" + this.state.seconds.toString()
    );
  }
}
