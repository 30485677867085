import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Button } from "react-bootstrap";

class DineIN extends Component {
  state = {};
  render() {
    const guestNumbers = [1, 2, 3, 4, 5, 6, 7, 8];
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const times = ["8am", "10pm"];
    return (
      <div id="dineIn">
        <div className="header">
          <Link to="/">
            <FontAwesomeIcon icon={faChevronLeft} />
          </Link>
          <div className="heading">Dine In</div>
        </div>
        <p className="restroName">Backside Bistro</p>
        <div className="divider"></div>
        <div className="reservationDetails">
          <p>Reservation Details</p>
          <Form>
            <Form.Group controlId="details.guest">
              <Form.Label>Number of guest</Form.Label>
              <Form.Control as="select">
                <option>Selection</option>
                {guestNumbers.map((guestNumber) => {
                  return <option key={guestNumber}>{guestNumber}</option>;
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="details.days">
              <Form.Label>Select Day</Form.Label>
              <Form.Control as="select">
                <option>Selection</option>
                {days.map((day, key) => {
                  return <option key={key}>{day}</option>;
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="details.time">
              <Form.Label>Select Time</Form.Label>
              <Form.Control as="select">
                <option>Selection</option>
                {times.map((time, key) => {
                  return <option key={key}>{time}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        <div className="divider"></div>
        <div className="reservationDetails">
          <p>Club Membership </p>
          <Form>
            <Form.Group controlId="details.club">
              <Form.Label>Membership Number</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Please enter here"
              />
              <div className="text-right required">Required</div>
            </Form.Group>
          </Form>
        </div>
        <div></div>
        <div className="footer">
          <Link to="pickup">
            <Button variant="danger">Next</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default DineIN;
