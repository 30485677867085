import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Button } from "react-bootstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class QuantityInfoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: props.quantity ? props.quantity : 0,
      message: props.message,
    };
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.onClose}
        id="dialog"
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Oops!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {this.state.quantity > 0 && !this.state.message && (
              <>
                The item you have selected has only {this.state.quantity}{" "}
                quantity.
              </>
            )}
            {this.state.quantity === 0 && !this.state.message && (
              <>The item you have selected is no longer available.</>
            )}
            {this.state.message && <>{this.state.message}</>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="danger"
            onClick={() => {
              this.props.onClose();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default QuantityInfoDialog;
