import React, { Component } from "react";
import { VerifyOrderKey } from "../../Utility/Config";
import { Storage as _storage } from "../../Utility/Storage";

class OrderVerification extends Component {
  render() {
    return (
      <div id="orderVerification">
        <p className="cen-div">
          Please click the link send to your email/phone to verify order
        </p>
      </div>
    );
  }
}

export default OrderVerification;
