import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Button } from "react-bootstrap";
import { MembershipKey } from "../../Utility/Config";
import {validateMemberShipNumber} from "../../Utility/URLService";
import { get } from "../../Utility/HttpService";
import { Storage } from '../../Utility/Storage';
import Spinner from "../../Utility/Spinner";

class MembershipValidation extends Component {  
  constructor(props, context) {
    super(props, context);
  
    this.state = {
      open: false,
      id: null,
      membershipNumber: "",
      isMembershipValid: true,
      membership: {},
      spinner: {
        show: false
      }, 
    };
  }

  componentDidMount(){   
    let membership = this.getMemberShipData();
    membership.isValid = false; 
    this.setState({ membership });    
  };

  showSpinner = (show) => {
    let spinner = this.state.spinner;    
    spinner.show = show;      
    this.setState({ spinner });   
  };

  getMemberShipData = () => {
    const _key = this.props.match.params.restaurantNodeId.toString() + MembershipKey;
    const membership = Storage.getItem(_key);
    return JSON.parse(membership);
  }

  validateMemberShip=()=> {
    this.showSpinner(true);
    return get(
      validateMemberShipNumber +
        `?membershipNumber=` +
        this.state.membershipNumber
    )
      .then((result) => result.data)
      .then((data: any) => {
        this.setState({isMembershipValid: data});
        if(data === true){
          let memberShipData = this.getMemberShipData();
          memberShipData.isValid = true;
          Storage.setItem(this.props.match.params.restaurantNodeId.toString() + MembershipKey, JSON.stringify(memberShipData));
          this.props.history.push('/pickup/'+this.props.match.params.restaurantNodeId);
        }
        this.showSpinner(false);
      });
  }

  handleChange(field, e) {
    this.setState({ membershipNumber: e.target.value });
  }

  renderMemberShipValidationDetails() {
    return (
      <>
       <Spinner show={this.state.spinner.show} value={this.state.spinner}></Spinner>
        <div className="reservationDetails">
          <p>{this.state.membership.headerText}</p>
          <Form>
            <Form.Group controlId="details.club">
              <Form.Label>
                {this.state.membership.labelText}
              </Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Please enter here"
                maxLength={15}
                onChange={this.handleChange.bind(this, "membershipNumber")}
              />
              <div className="text-right required">Required</div>
              {this.state.membershipNumber &&
                (this.state.isMembershipValid === false) && (
                  <div className="text-right required text-danger">
                    {this.state.membership.invalidInputMessage}
                  </div>
                )}
            </Form.Group>
          </Form>
        </div>
        <div></div>
      </>
    );
  }
  render() {
    return (
      <>
        <div id="dineIn">
          <div className="header">
            <Link to="/">
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
            <div className="heading">PICKUP</div>
          </div>
          <p className="restroName">{this.props.match.params.restaurantName}</p>
          <div className="divider"></div>
          {this.state.membership &&
            this.renderMemberShipValidationDetails()}          
          <div className="footer">
            {/* <Link to="dineIn"> */}
              <Button variant="danger" onClick={this.validateMemberShip}>Next</Button>
            {/* </Link> */}
          </div>
        </div>
      </>
    );
  }
}

export default MembershipValidation;
