import React, { Component, Fragment } from "react";
import Header from "../Header/NavigationHeader";
import food from "../../assets/images/food.svg";
import { Form, Button } from "react-bootstrap";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FNBAppBaseURL, KIOSKSetting } from "../../Utility/Config";
import {
  getFoodItemWithCustomization,
  checkCartIdExists,
} from "../../Utility/URLService";
import { get } from "../../Utility/HttpService";
import { formatPrice } from "../../Utility/Common";
import { storage } from "../../Utility/CartStorage";
import { FoodItemWithCustomization } from "../../Models/FoodItemWithCustomization";
import { CommonResponse } from "../../Models/CommonResponse";
import CartExpirationDialog from "../../Utility/CartExpirationDialog";
import { ExpiryCheck_IntervalMinutes } from "../../Utility/Config";
import QuantityInfoDialog from "../QuantityInfo/QuantityInfo";
import Cart from "../../components/Cart/Cart";
import { Storage as _storage } from "../../Utility/Storage";

class Customize extends Component {
  constructor(props) {
    super(props);
    this.restaurantInfo = storage.getRestaurantInfo();
    this.state = {
      count: 0,
      navigateFrom: "customize",
      previousPath: `/food-categories/${this.restaurantInfo.menuNodeId}`,
      foodItemQtyCount: 0,
      redirect: false,
      foodItem: {},
      customizations: [],
      Modal: {
        visible: false,
        cartRemoval: false,
        closeEventCallBack: this.expirationModalCloseCallback,
      },
      cartModal: {
        visible: false,
      },
      heading: "SELECT YOUR ITEMS",
      quantityModal: {
        visible: false,
        onCloseEventCallBack: this.quantityModalCloseCallBack,
        quantity: 0,
      },
      kioskSetting: JSON.parse(_storage.getItem(KIOSKSetting)),
      screenRefreshTime: 0,
      isRefreshScreen: false,
    };
    this.getFoodItemWithCustomization(
      props.match.params.nodeId,
      props.match.params.customNodeId
    );
  }

  quantityModalCloseCallBack = () => {
    this.checkAndRefreshScreenTime();
    let forceReload = false;
    let quantityModal = this.state.quantityModal;
    if (quantityModal.quantity === 0) forceReload = true;
    if (forceReload) {
      //this.props.history.push(this.state.previousPath);
    } else {
      quantityModal.visible = false;
      quantityModal.quantity = 0;
      this.setState({
        quantityModal,
      });
    }
  };

  expirationModalCloseCallback = (type) => {
    if (this.state.Modal.cartRemoval) {
      storage.reInitCartWhenCartIdInvalid();
    } else {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      this.setState({ Modal });
      this.getCartCount(true);
    }
    if (type === "Expired") {
      let redirectURL = _storage.getRedirectURL();
      this.props.history.push(redirectURL);
    }
  };
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.MenuSelection);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = (toMainUrl = false) => {
    let redirectURL = toMainUrl ? "/" : _storage.getRedirectURL();
    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
  };
  checkAndRefreshScreenTime = () => {
    let { screenRefreshTime, kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime =
        parseInt(kioskSetting.MenuSelection) > 0
          ? parseInt(kioskSetting.MenuSelection)
          : 0;
      this.setState({ screenRefreshTime });
    }
  };
  componentDidMount() {
    this.getCartCount();
    this.cartExpiryTimeOut = setInterval(
      this.checkCartExpiryTimeOut,
      ExpiryCheck_IntervalMinutes * 60 * 1000
    );
    this.checkAndRedirectToMainPage();
  }

  componentWillUnmount() {
    clearInterval(this.cartExpiryTimeOut);
    this.state.screenRefreshTime > 0 && clearTimeout(this.confirmationTimer);
  }

  checkCartExpiryTimeOut = () => {
    if (!this.state.Modal.visible) {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      if (storage.checkCartItemsExpiration()) {
        Modal.visible = true;
      }
      this.setState({ Modal });
    }
  };

  checkPreviousSelections = () => {
    if (this.props.match.params.id) {
      let selectedItem = storage.getFoodItem(null, this.props.match.params.id);
      // check previous selection with customization
      if (selectedItem && selectedItem.length === 1) {
        let _selectedItem = selectedItem[0];
        let foodItem = this.state.foodItem;
        foodItem.id = this.props.match.params.id;
        foodItem.quantity = _selectedItem.quantity;
        this.setState({
          foodItem,
          foodItemQtyCount: _selectedItem.quantity,
        });
      }

      if (
        selectedItem &&
        selectedItem.length === 1 &&
        selectedItem[0].customizations.length > 0
      ) {
        selectedItem = selectedItem[0];
        let customizations = this.state.customizations;
        let selectedCustomizations = selectedItem.customizations;
        // let foodItem = this.state.foodItem;
        // foodItem.quantity = selectedItem.quantity;

        customizations.forEach((customization) => {
          const customizationNodeId = customization.nodeId;
          let selectedCustomization = selectedCustomizations.filter((item) => {
            return item.nodeId.toString() === customizationNodeId.toString();
          });
          if (
            selectedCustomization == null ||
            selectedCustomization.length === 0
          ) {
            return;
          } else {
            selectedCustomization = selectedCustomization[0];
          }

          if (
            customization.customizationOptions.length > 0 &&
            selectedCustomization.customizationOptions.length > 0
          ) {
            customization.customizationOptions.filter((option) => {
              let optionNodeId = option.nodeId;
              option.isSelected = false;
              const selectedOption =
                selectedCustomization.customizationOptions.filter(
                  (selectedOption) => {
                    return (
                      selectedOption.nodeId.toString() ===
                      optionNodeId.toString()
                    );
                  }
                );
              if (selectedOption.length === 1) {
                option.isSelected = true;
              }
              return option;
            });
          } else if (selectedCustomization.hasOwnProperty("textBoxValue")) {
            // If no customization option means, check textbox values in previous selection
            document.getElementById(customizationNodeId).value =
              selectedCustomization.textBoxValue;
          }
        });
        this.setState({
          //foodItem,
          customizations,
          //foodItemQtyCount: selectedItem.quantity,
        });
      }
    }
  };

  getFoodItemWithCustomization(nodeId, customizeNodeId) {
    get(
      getFoodItemWithCustomization +
        "?restaurantNodeId=" +
        this.restaurantInfo.nodeId +
        "&menuNodeId=" +
        this.restaurantInfo.menuNodeId +
        "&nodeId=" +
        nodeId +
        "&customizeNodeId=" +
        customizeNodeId
    )
      .then((result) => result.data)
      .then((data: FoodItemWithCustomization) => {
        let model = data;
        if (
          model.foodItem &&
          model.foodItem.hasOwnProperty("customizeNodeId") &&
          model.customizations.length > 0
        ) {
          model.foodItem.customizeNodeId = Number(customizeNodeId);
          this.setState({
            foodItem: model.foodItem ? model.foodItem : {},
            customizations: model.customizations ? model.customizations : [],
            foodItemQtyCount: model.foodItem.minimumQuantity,
          });
          this.checkPreviousSelections();
        }
      })
      .catch((error) => {
        console.log(error && error.message);
        this.refreshScreen(true);
      });
  }

  getCartCount = () => {
    let cartInfo = storage.info();
    this.setState({
      count: cartInfo.totalCount,
      totalPrice: cartInfo.totalPrice,
    });
  };

  getElementType = (required, selectAllByDefault) => {
    let controlType = "checkbox";
    if (!selectAllByDefault && required) controlType = "radio";

    return controlType;
  };

  validateOptionSelections = () => {
    const customizations = this.state.customizations;
    let validSelections = true;
    customizations.forEach(function (customization) {
      if (validSelections) {
        let required = customization.required;
        const selectedOptions = customization.customizationOptions.filter(
          (option) => {
            return option.isSelected === true;
          }
        );
        if (required && selectedOptions.length === 0) validSelections = false;
      }
    });
    return validSelections;
  };

  customizationOptionChange = (index, optionIndex, optionNodeId) => {
    this.checkAndRefreshScreenTime();
    let customizations = this.state.customizations;
    let required = customizations[index].required;
    if (required) {
      // under this customization only one option is selectable
      customizations[index].customizationOptions.filter((item) => {
        if (item.nodeId === optionNodeId) item.isSelected = true;
        else item.isSelected = false;
        return item;
      });
    } else {
      customizations[index].customizationOptions[optionIndex].isSelected =
        !customizations[index].customizationOptions[optionIndex].isSelected;
    }
    this.setState({
      customizations,
    });
  };

  generateCustomization = () => {
    const optionsList = this.state.customizations.map((item, index) => {
      let customizationOptions = item.customizationOptions;
      let controlType = this.getElementType(
        item.required,
        item.selectAllByDefault
      );
      return (
        <Fragment key={item.nodeId}>
          <div className="label-section">
            <p>
              {item.name}
              {item.required ? <span>Required</span> : null}
            </p>
            {item.required ? (
              <p className="small-text">Please Choose 1</p>
            ) : null}
          </div>
          {customizationOptions.length > 0 && (
            <Form.Group>
              {customizationOptions.map((option, optionIndex) => (
                <div className="mb-3">
                  <div key={option.nodeId} className="d-flex">
                    <Form.Check
                      value={option.nodeId}
                      name={
                        controlType === "radio" ? item.nodeId : option.nodeId
                      }
                      type={controlType}
                      id={option.nodeId}
                      label={option.name}
                      onChange={(e) =>
                        this.customizationOptionChange(
                          index,
                          optionIndex,
                          option.nodeId
                        )
                      }
                      checked={option.isSelected}
                    />
                    {option.price > 0 ? (
                      <p>{"+ " + formatPrice(option.price)}</p>
                    ) : null}
                  </div>
                  {option.description && (
                    <div className="modifier-desc">{option.description}</div>
                  )}
                </div>
              ))}
            </Form.Group>
          )}
          {customizationOptions.length === 0 && (
            <Form.Group
              className="pt-0 last-child"
              //controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label></Form.Label>
              <Form.Control as="textarea" rows="3" id={item.nodeId} />
            </Form.Group>
          )}
        </Fragment>
      );
    });
    return optionsList;
  };

  generateFoodItemImage = () => {
    let src = food;
    if (FNBAppBaseURL && this.state.foodItem.imageUrl) {
      src = FNBAppBaseURL + this.state.foodItem.imageUrl;
      return <img src={food} alt="food" width="100%" />; //  need to update src
    } else {
      return null;
    }
  };

  render() {
    return (
      <div id="customize">
        <Header value={this.state} cartIconClickEvent={this.onViewCart} />
        {this.generateFoodItemImage()}
        <div className="header-section">
          <div
            className={`increment ${
              this.state.foodItem.imageUrl ? "" : "no-img"
            }`}
          >
            <FontAwesomeIcon
              onClick={() => this.handleFoodItemQuantityClick("D")}
              icon={faMinus}
            />
            <p>{this.state.foodItemQtyCount}</p>
            <FontAwesomeIcon
              onClick={() => this.handleFoodItemQuantityClick("I")}
              icon={faPlus}
            />
          </div>
          <h4>{this.state.foodItem.name}</h4>
          <h6>{formatPrice(this.state.foodItem.price)}</h6>
          <p>{this.state.foodItem.description}</p>
        </div>

        <Form>
          {this.generateCustomization()}
          <Button
            variant={this.validateOptionSelections() ? "danger" : "secondary"}
            onClick={() => this.addTocart()}
            disabled={!this.validateOptionSelections()}
          >
            Add to Order
          </Button>
          {this.state.cartModal.visible && (
            <Cart
              onCartScreenClose={this.onCartScreenClose}
              checkAndRefreshScreenTime={this.checkAndRefreshScreenTime}
            ></Cart>
          )}
          {this.state.Modal.visible && (
            <CartExpirationDialog modal={this.state.Modal} />
          )}
          {this.state.quantityModal.visible && (
            <QuantityInfoDialog
              open={true}
              quantity={this.state.quantityModal.quantity}
              onClose={this.state.quantityModal.onCloseEventCallBack}
            />
          )}
        </Form>
      </div>
    );
  }

  handleFoodItemQuantityClick = (value) => {
    this.checkAndRefreshScreenTime();
    if (
      value === "I" &&
      this.state.foodItem.maximumQuantity < this.state.foodItemQtyCount + 1
    )
      return false;
    if (
      value === "D" &&
      this.state.foodItem.minimumQuantity > this.state.foodItemQtyCount - 1
    )
      return false;
    this.setState({
      foodItemQtyCount:
        value === "I"
          ? this.state.foodItemQtyCount + 1
          : this.state.foodItemQtyCount - 1,
    });
  };

  addTocart = () => {
    if (this.state.foodItemQtyCount - this.state.foodItem.quantity !== 0) {
      storage.verifyFoodItemWithMax4Sale(
        this.state.foodItem,
        this.state.foodItemQtyCount - this.state.foodItem.quantity,
        this.handleMax4SaleSuccessCallBack,
        this.handleMax4SaleFailureCallBack
      );
    } else {
      this.handleMax4SaleSuccessCallBack();
    }
  };

  showCartRemovalMessage = () => {
    let Modal = {
      ...this.state.Modal,
      visible: true,
      cartRemoval: true,
    };
    this.setState({ Modal });
  };

  handleMax4SaleSuccessCallBack = () => {
    let selectedCustomizations = [];
    this.state.customizations.forEach((customization) => {
      let _customization = { ...customization };
      const options = _customization.customizationOptions;
      _customization.customizationOptions = [];

      if (options.length > 0) {
        let selectedOptions = options.filter((option) => {
          return option.isSelected === true;
        });
        _customization.customizationOptions = selectedOptions;
      } else {
        _customization.textBoxValue = "";
        let value = document.getElementById(_customization.nodeId).value;
        if (value) {
          _customization.textBoxValue = value;
        }
      }
      selectedCustomizations.push(_customization);
    });
    let foodItem = this.state.foodItem;
    foodItem.quantity = this.state.foodItemQtyCount;
    storage.update(foodItem, selectedCustomizations);
    this.props.history.push(this.state.previousPath);
  };

  handleMax4SaleFailureCallBack = (response) => {
    if (response.message === "cartId_unavailable") {
      this.showCartRemovalMessage();
    } else {
      let quantity = 0;
      if (response.data) {
        const quantityJson = JSON.parse(response.data);
        quantity = quantityJson.availableQty;
      }
      let quantityModal = this.state.quantityModal;
      quantityModal.quantity = quantity;
      quantityModal.visible = true;
      this.setState({ quantityModal });
    }
  };

  onViewCart = () => {
    this.checkAndRefreshScreenTime();
    let cartInfo = storage.info();
    if (cartInfo.totalCount > 0) {
      get(checkCartIdExists + "?cartId=" + cartInfo.cartId)
        .then((result) => result.data)
        .then((data: CommonResponse) => {
          if (data.status) {
            // cartId exists
            let cartModal = {
              ...this.state.cartModal,
              visible: true,
            };
            this.setState({ cartModal });
          } else {
            // cartId not exists
            this.showCartRemovalMessage();
          }
        });
    }
  };

  onCartScreenClose = () => {
    this.checkAndRefreshScreenTime();
    let cartModal = {
      ...this.state.cartModal,
      visible: false,
    };
    this.setState({ cartModal });
  };
}

export default Customize;
