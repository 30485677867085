import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faShoppingCart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Storage } from "../../Utility/Storage";
import { CartItemKey } from "../../Utility/Config";
import { storage as cartStorage } from "../../Utility/CartStorage";

class Header extends Component {
  state = {};
  render() {
    return (
      <div className="header">
        <Link to={this.getRoutingURL()}>
          {this.props.value.closeIcon ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            !this.props.isHideBackButton && <FontAwesomeIcon icon={faChevronLeft} />
          )}
        </Link>
        <div className="heading">{this.props.value.heading}</div>
        {this.props.value.hideCart ? null : (
          <div
            className={this.getCartClasses()}
            onClick={this.handleCartIconClick}
          >
            <FontAwesomeIcon icon={faShoppingCart} />
            <span>{this.getCartCount()}</span>
          </div>
        )}
      </div>
    );
  }

  handleCartIconClick = () => {
    if (this.props.cartIconClickEvent) {
      this.props.cartIconClickEvent();
    }
  };

  getCartCount = () => {
    let cartItems = JSON.parse(Storage.getItem(CartItemKey));
    if (cartItems) {
      if (cartItems.totalQuantityCount) {
        return cartItems.totalQuantityCount;
      }
    }
    return 0;
  };

  getCartClasses() {
    let classes = "cartIconContainer right";
    classes += this.getCartCount() > 0 ? " background" : "";
    return classes;
  }

  getRoutingURL() {
    const restaurantInfo = cartStorage.getRestaurantInfo();
    let url="";
    if (restaurantInfo) {
      switch (this.props.value.navigateFrom) {
        case "expanded-menu":
          url = "/pickup/" + restaurantInfo.nodeId.toString();
          break;
        case "pickup":
          let isKioskActive = Storage.checkKioskActive();
          url = isKioskActive ? "/?kiosk=true" : "/";
          break;
        case "customize":
        case "checkout":
          if (
            restaurantInfo.hasOwnProperty("menuNodeId") &&
            restaurantInfo.menuNodeId
          )
            url = "/food-categories/" + restaurantInfo.menuNodeId.toString();
          else url = "/pickup/" + restaurantInfo.nodeId.toString();
          break;
        default:
          break;
      }
    }
    return url;
  }
}

export default Header;
