import { cryptoJS } from "./Common";
import {
  KIOSKSetting,
  PassInfoKey,
  Required_CryptoSecurity,
  RestaurantDetailsByNodeId,
  VerifyOrderKey,
} from "./Config";

const getItemFromStorage = (item) => {
  let cipherText = window.localStorage.getItem(item);
  let returnValue = "";
  if (Required_CryptoSecurity && cipherText)
    returnValue = cryptoJS.decryption(cipherText);
  else returnValue = cipherText;

  return returnValue;
};
const removeFromStorage = (item) => {
  window.localStorage.removeItem(item);
};
const setItemToStorage = (item, content) => {
  let _content = Required_CryptoSecurity
    ? cryptoJS.encryption(content)
    : content;
  window.localStorage.setItem(item, _content);
  return true;
};

const updateRestaurantInfo = (restaurantInfo) => {
  setItemToStorage(RestaurantDetailsByNodeId, restaurantInfo);
};

const getRedirectURL = () => {
  let redirectURL = "/";
  let kioskSetting = JSON.parse(getItemFromStorage(KIOSKSetting));
  if (kioskSetting && kioskSetting.IsKioskActive)
    redirectURL = kioskSetting.RedirectUrl;
  return redirectURL;
};

const checkKioskActive = () => {
  let kioskSetting = JSON.parse(getItemFromStorage(KIOSKSetting));
  return kioskSetting && kioskSetting.IsKioskActive;
};

const saveVerifyOrderInfo = (verifyOrderInfo) => {
  Storage.setItem(VerifyOrderKey, JSON.stringify(verifyOrderInfo));
};

const clearPassInformation = () => {
  let passInfo = {
    firstName: "",
    lastName: "",
    passNumber: "",
    emailId: "",
    phoneNumber: "",
    isPassApplied: false,
    specialText: "",
    isPassInfoManuallyEntered: false
  };
  Storage.setItem(PassInfoKey, JSON.stringify(passInfo));
};

export const Storage = {
  setItem: setItemToStorage,
  getItem: getItemFromStorage,
  removeItem: removeFromStorage,
  updateRestaurantInfo,
  getRedirectURL,
  checkKioskActive,
  verifyOrderInfo: saveVerifyOrderInfo,
  clearPassInformation,
};
