import {
  faCheck,
  faCheckCircle,
  faChevronRight,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import { AppInfo } from "../../Models/AppInfo";
import { storage as CartStorage } from "../../Utility/CartStorage";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";

import {
  MembershipKey,
  SMSInfoKey,
  KIOSKSetting,
  OrderAgainNumber,
  CartItemKey,
  OrderDetails,
} from "../../Utility/Config";
import { get } from "../../Utility/HttpService";
import Spinner from "../../Utility/Spinner";
import { Storage } from "../../Utility/Storage";
import {
  getRestaurantsURL,
  GetCouponDetailsFromToken,
  getGuestInfoByToken,
} from "../../Utility/URLService";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Storage as _storage } from "../../Utility/Storage";
import {
  PassInfoKey,
  CouponCodeKey,
  IsFromCouponToken,
  IsFromGuestToken,
} from "../../Utility/Config";

class Restaurants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appInfo: {},
      restaurants: [],
      spinner: {
        show: false,
      },
      queryParamvalues: {
        isKioskActive: false,
        restaurantOrderList: [],
      },
      couponCode: "",
      toaster: [],
    };
  }
  componentDidMount() {
    CartStorage.initializeCart();
    //this.getAppinformations();
    this.clearPassInformationIntoStorage();
    this.getQueryParams();
    //this.getQueryParamsWithToken();
    Storage.removeItem(OrderAgainNumber);
    Storage.removeItem(OrderDetails);
    // Storage.removeItem(CouponCodeKey);
    // Storage.removeItem(CartItemKey);
  }
  clearPassInformationIntoStorage = () => {
    let passInfo = {
      firstName: "",
      lastName: "",
      passNumber: "",
      emailId: "",
      phoneNumber: "",
      isPassApplied: false,
      specialText: "",
    };
    Storage.setItem(PassInfoKey, JSON.stringify(passInfo));
    let isFromGuestToken = false;
    Storage.setItem(IsFromGuestToken, JSON.stringify(isFromGuestToken));
  };
  getQueryParams = () => {
    let { queryParamvalues } = this.state;
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams) {
        let kioskValue = queryParams.get("kiosk");
        let restaurantName = queryParams.get("restaurant");
        let stToken = queryParams.get("st");
        let gtToken = queryParams.get("gt");

        if (kioskValue && kioskValue.toString().toLowerCase() === "true") {
          queryParamvalues.isKioskActive = true;
          if (restaurantName) {
            let restaurantlist = restaurantName
              .replaceAll(" ", "")
              .toLowerCase()
              .split(",");
            queryParamvalues.restaurantOrderList = restaurantlist.reverse();
          }
        } else if (stToken) {
          this.getCouponDetails(stToken);
        } else if (gtToken) {
          this.getGuestDetails(gtToken);
        }
      } else {
        this.showToasterMessage("Please add items to cart", "e");
        setTimeout(() => {
          this.props.history.push("/");
        }, 5000);
      }
    }
    this.setState({ queryParamvalues }, () => {
      this.getAppinformations();
    });
  };

  getCouponDetails = (token) => {
    let { couponCode } = this.state;
    if (!token) return;
    let url = `${GetCouponDetailsFromToken}${token}`;
    get(url)
      .then((result) => result.data)
      .then((data) => {
        if (data) {
          couponCode = data;
          if (couponCode) {
            let foodItems = [];
            let storedItems = CartStorage.getAllFoodItems();
            _storage.setItem(CouponCodeKey, data);
            _storage.setItem(IsFromCouponToken, true);
            this.props.history.push("/pickup/" + storedItems.restaurantNodeId);
          }
        } else {
          this.showToasterMessage("You have entered invalid token", "e");
          setTimeout(() => {
            this.props.history.push("/");
          }, 5000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGuestDetails = (token) => {
    if (!token) return;
    let url = `${getGuestInfoByToken}${token}`;
    get(url)
      .then((result) => result)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status) {
            let guestInfo = JSON.parse(response?.data?.data);
            let passInfo = {
              firstName: guestInfo.firstName,
              lastName: guestInfo.lastName,
              emailId: guestInfo.email,
              phoneNumber: guestInfo.phoneNumber,
              guestNumber: guestInfo.guestNumber, 
            };
            _storage.setItem(PassInfoKey, JSON.stringify(passInfo));
            _storage.setItem(IsFromGuestToken, true);
            let storedItems = CartStorage.getAllFoodItems();
            this.props.history.push("/pickup/" + storedItems.restaurantNodeId);
          } else {
            this.showToasterMessage(response.data.message, "e");
            setTimeout(() => {
              this.props.history.push("/");
            }, 5000);
          }
        } else {
          this.showToasterMessage("You have entered invalid token", "e");
          setTimeout(() => {
            this.props.history.push("/");
          }, 5000);
        }
      })
      .catch((error) => {
        this.showToasterMessage("You have entered invalid token", "e");
        setTimeout(() => {
          this.props.history.push("/");
        }, 5000);
      });
  };

  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };
  getAppinformations = () => {
    this.showSpinner(true);
    return get(getRestaurantsURL)
      .then((result) => result.data)
      .then((data) => {
        let { queryParamvalues } = this.state;
        let restaurants = data.restaurants ? data.restaurants : [];
        let restaurantOrderList = queryParamvalues.restaurantOrderList;
        if (
          restaurantOrderList &&
          restaurantOrderList.length > 0 &&
          restaurants &&
          restaurants.length > 0
        ) {
          restaurants.sort(function (a, b) {
            return (
              restaurantOrderList.indexOf(
                b.name.replaceAll(" ", "").toLowerCase()
              ) -
              restaurantOrderList.indexOf(
                a.name.replaceAll(" ", "").toLowerCase()
              )
            );
          });
        }

        delete data.restaurants;
        const appInfo = data;
        let kioskSetting = JSON.parse(data.kioskSetting);
        if (kioskSetting) {
          kioskSetting["IsKioskActive"] = queryParamvalues.isKioskActive;
          kioskSetting["RedirectUrl"] = queryParamvalues.isKioskActive
            ? `/?kiosk=true`
            : "/";
          this.SaveKIOSKInfoIntoStorage(kioskSetting);
        }
        if (data.smsConditionInfo)
          this.SaveSMSInfoIntoStorage(data.smsConditionInfo);
        this.setState({ restaurants, appInfo });
        this.showSpinner(false);
      });
  };

  SaveSMSInfoIntoStorage = (smsInfoText) => {
    let smsInfo = { smsInfo: smsInfoText };
    Storage.setItem(SMSInfoKey, JSON.stringify(smsInfo));
  };

  SaveKIOSKInfoIntoStorage = (kioskSetting) => {
    _storage.setItem(KIOSKSetting, JSON.stringify(kioskSetting));
  };

  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };

  render() {
    let { toaster } = this.state;
    return (
      <Fragment>
        {toaster?.length === 1 && (
          <Toaster
            message={toaster[0].message}
            type={toaster[0].type}
            id={toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        <Spinner
          show={this.state.spinner.show}
          value={this.state.spinner}
        ></Spinner>

        <div className="mobile-header">
          <Header appInfo={this.state.appInfo} />
        </div>
        <div id="restaurants" className="container">
          {this.state.restaurants.length > 0 && this.buildRestaurants()}
        </div>
        <Footer />
      </Fragment>
    );
  }

  getFoodCategory(foodCategory, key) {
    return (
      <p key={key}>
        <FontAwesomeIcon icon={faCheck} />
        {foodCategory.name}
      </p>
    );
  }

  hanldeIsBusinessDaysContainerOpen = (restaurant, index) => {
    let restaurants = this.state.restaurants;
    restaurants[index].isBusinessDaysContainerOpen =
      !restaurants[index].isBusinessDaysContainerOpen;
    this.setState({
      restaurants,
    });
  };

  hanldeBusinessDaysContainer(restaurant, index) {
    if (restaurant.isAvailable) {
      return (
        <Fragment>
          <span
            onClick={() => {
              this.hanldeIsBusinessDaysContainerOpen(restaurant, index);
            }}
            aria-controls="example-collapse-text"
            aria-expanded={restaurant.isBusinessDaysContainerOpen}
          >
            Open now
          </span>
          <FontAwesomeIcon icon={faChevronRight} className="chev-right" />
        </Fragment>
      );
    } else {
      return <span>Not accepting online orders at the moment.</span>;
    }
  }

  handleBusinessDaysListing(restaurant) {
    if (!restaurant.businessDays || restaurant.businessDays.length === 0) {
      return null;
    }
    return (
      <Collapse in={restaurant.isBusinessDaysContainerOpen}>
        <div id="timings">
          {restaurant.businessDays.map((Day, key) => {
            return (
              <div className="d-flex" key={key}>
                <p>{Day.name}</p>
                <p>{Day.startTime}</p>{" "}
                <p style={{ marginLeft: "5px", marginRight: "5px" }}>-</p>{" "}
                <p>{Day.endTime}</p>
              </div>
            );
          })}
        </div>
      </Collapse>
    );
  }

  handleOrderClick = (restaurant) => {
    let membership = restaurant.membership;
    const _key = restaurant.nodeId.toString() + MembershipKey;
    Storage.setItem(_key, JSON.stringify(membership));
    const membershipPage = `/pickupvalidation/${restaurant.nodeId}/${restaurant.name}`;
    this.props.history.push(membershipPage);
  };

  buildRestaurants() {
    const { restaurants } = this.state;
    return restaurants.map((restaurant, key) => {
      return (
        <div className="section" key={key}>
          <p className="heading">{restaurant.name}</p>
          <div className="pills d-flex">
            {restaurant.foodCategories.map((foodCategory, index) => {
              return this.getFoodCategory(foodCategory, index);
            })}
          </div>
          <p className="desc">{restaurant.description}</p>
          <div className="list-item">
            <p>
              <FontAwesomeIcon icon={faMapPin} />
              {restaurant.location}
            </p>
            <p>
              {!restaurant.isRestaurantTakingOnlineOrder &&
                restaurant.isAvailable && (
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span>Not accepting online orders at the moment.</span>
                  </div>
                )}
            </p>
            <p>
              {restaurant.restaurantCloseMessage && (
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span>{restaurant.restaurantCloseMessage}</span>
                </div>
              )}
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} />
              {this.hanldeBusinessDaysContainer(restaurant, key)}
            </p>
            {this.handleBusinessDaysListing(restaurant)}
          </div>

          <div
            className="btn-section"
            style={{ display: restaurant.isAvailable ? "block" : "none" }}
          >
            {restaurant.isDineInAvailable && (
              <Link to="/dineIn">
                <Button variant="danger">Dine In</Button>
              </Link>
            )}
            {restaurant.membership.isRequired &&
              restaurant.isRestaurantTakingOnlineOrder && (
                <Button
                  variant="danger"
                  onClick={() => {
                    this.handleOrderClick(restaurant);
                  }}
                >
                  Order
                </Button>
              )}

            {!restaurant.membership.isRequired &&
              restaurant.isRestaurantTakingOnlineOrder && (
                <Link to={`/pickup/${restaurant.nodeId}`}>
                  <Button variant="danger">Order</Button>
                </Link>
              )}
          </div>
        </div>
      );
    });
  }
}

export default Restaurants;
