import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Spinner(props) {  
  const classes = useStyles();
  //const [open, setOpen] = React.useState(props.show);
  // const handleClose = () => {
  //   setOpen(false);
  //   this.props.value.handleClose();
  // }; 
  //console.log("spinner props", props);
  return (
    <div>      
      <Backdrop className={classes.backdrop} open={props.show}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}